<template>
  <div class="warp">
    <login-case type="forget">
      <div class="hd f26 text-center mt40">
        <div class="item">找回密码</div>
      </div>
      <div class="entry pt40">
        <div class="input mb15">
          <input
            type="text"
            placeholder="手机号/账号"
            v-model="params.mobile"
          />
        </div>
        <div class="input flex mb15">
          <input
            type="text"
            placeholder="图形验证码"
            class="flex-1"
            v-model="imgCode"
          />
          <img :src="imgUrl" class="graph mr30 cup" @click="getImgCode" />
        </div>
        <div class="input flex mb15">
          <input
            type="text"
            placeholder="短信验证码"
            class="flex-1"
            v-model="params.code"
          />
          <span class="code cup f18 mr30" @click="getCode">{{
            getCodeText
          }}</span>
        </div>
        <div class="input flex mb15">
          <input
            :type="pass[0] == 0 ? 'password' : 'text'"
            placeholder="新密码"
            class="flex-1"
            v-model="params.password"
          />
          <img
            :src="pass[0] == 0 ? imgSrc2 : imgSrc1"
            class="see mr30 cup"
            @click="look(0)"
          />
        </div>
        <div class="input flex">
          <input
            :type="pass[1] == 0 ? 'password' : 'text'"
            placeholder="重复密码"
            class="flex-1"
            v-model="params.repassword"
          />
          <img
            :src="pass[1] == 0 ? imgSrc2 : imgSrc1"
            class="see mr30 cup"
            @click="look(1)"
          />
        </div>
      </div>
      <div class="login-btn f18 text-center mt40" @click="resetpwd">提交</div>
    </login-case>
    <p class="web">
      <a href="http://beian.miit.gov.cn/" target="_blank">
        备案号：粤ICP备2023066121号-6
      </a>
    </p>
  </div>
</template>

<script>
import loginCase from '../../components/loginCase.vue'
export default {
  name: 'login',
  components: {
    loginCase
  },
  data() {
    return {
      params: {
        mobile: '',
        code: '',
        password: '',
        repassword: ''
      },
      imgCode: '',
      imgUrl: '',
      num: 60,
      getCodeText: '获取验证码',
      timer: null,

      imgSrc1: require('../../assets/img/login/see.png'),
      imgSrc2: require('../../assets/img/login/not-see.png'),
      pass: [0, 0]
    }
  },
  mounted() {
    this.getImgCode()
  },
  methods: {
    async resetpwd() {
      if (!/^1[3-9]\d{9}$/.test(this.params.mobile)) {
        return this.$message.warning('请输入正确的手机号码')
      }
      if (!this.params.code) {
        return this.$message.warning('请输入短信验证码')
      }
      if (this.params.password != this.params.repassword) {
        return this.$message.warning('两次输入的密码不一致')
      }
      var res = await this.$api.post('/api/login/resetpwd', this.params)
      if (res.code == 0) {
        this.$message.success('修改成功')
        this.$router.push('/login')
      }
    },
    //获取短信验证码
    async getCode() {
      const _this = this
      if (this.getCodeText !== '获取验证码') {
        return false
      }
      if (!/^1[3-9]\d{9}$/.test(this.params.mobile)) {
        return this.$message.warning('请输入正确的手机号码')
      }
      var params = {
        mobile: this.params.mobile,
        t: 'findpwd',
        smsCaptchaCode: this.imgCode
      }
      var res = await this.$api.post(
        '/api/common/sendSmsCodeWithCaptcha',
        params
      )
      if (res.code == 0) {
        this.getCodeText = this.num + 's后重试'
        this.timer = setInterval(() => {
          if (_this.num === 1) {
            _this.getCodeText = '获取验证码'
            _this.num = 60
            clearInterval(_this.timer)
            _this.getImgCode()
          } else {
            _this.num--
            _this.getCodeText = this.num + 's后重试'
          }
        }, 1000)
      }
    },
    //获取图形验证码
    async getImgCode() {
      var params = {
        w: 105,
        h: 34,
        t: this.$util.random(1000, 9999)
      }
      var res = await this.$api.get('/app/captcha/sendSms', params, {
        responseType: 'arraybuffer'
      })
      var data =
        'data:image/png;base64,' +
        btoa(
          new Uint8Array(res).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ''
          )
        )
      this.imgUrl = data
    },
    look(index) {
      this.pass[index] = this.pass[index] == 0 ? 1 : 0
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="scss" scoped>
.warp {
  position: relative;
  .web {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    bottom: 40px;
  }
}
.login-btn {
  width: 100%;
  height: 62px;
  border-radius: 62px;
  line-height: 62px;
  background-color: #6672fb;
  color: #ffffff;
}
.entry {
  .input {
    width: 100%;
    height: 40px;
    border-radius: 40px;
    border: 1px solid #d9d9d8;
    .see {
      width: 31px;
      height: 22px;
      font-size: 0;
    }
    .code {
      color: #6672fb;
      font-weight: bold;
    }
    .graph {
      width: 105px;
      height: 34px;
    }
    input {
      width: 100%;
      line-height: 38px;
      border-radius: 40px;
      padding: 0 20px;
      border: none;
      box-sizing: border-box;
      font-size: 14px;
    }
    input::-webkit-input-placeholder {
      color: #999999;
      font-size: 14px;
    }
  }
}
.hd {
  width: 100%;
  height: 55px;
  border-bottom: 1px solid #efefef;
  .item {
    color: #6672fb;
  }
}
</style>
